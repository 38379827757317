import { useAuthStore } from '@/stores/auth';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'

// Définis les types pour tes routes
const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: () => import('../components/layouts/AppLayout.vue'),
		meta: { requiresAuth: true },
		children: [
			{
				path: '',
				name: 'Home',
				component: () => import('../views/HomeView.vue'),
			},
			{
				path: 'certificates',
				children: [
					{
						path: 'c',
						name: 'CertificatesClient',
						component: () => import('../views/certificates/CertificatesClientView.vue'),
					}
				]
			},
		],
	},
	{
		path: '/login',
		component: () => import('../components/layouts/LoginLayout.vue'),
		children: [
			{
				path: '',
				name: 'Login',
				component: () => import('../views/login/LoginView.vue'),
				meta: { requiresAuth: false },
			},
			{
				path: 'first',
				name: 'FirstLogin',
				component: () => import('../views/login/FirstLoginView.vue'),
				meta: { requiresAuth: true },
			},
		]
	},
	{
		path: '/faq',
		component: () => import('../components/layouts/SideLogoLayout.vue'),
		children: [
			{
				path: '',
				name: 'FAQ',
				component: () => import('../views/FAQView.vue'),
			}
		],
		meta: { requiresAuth: false },
	},
	{
		path: '/unsubscribe',
		component: () => import('../components/layouts/LoginLayout.vue'),
		children: [
			{
				path: '',
				name: 'Unsubscribe',
				component: () => import('../views/UnsubscribeView.vue'),
			}
		],
		meta: { requiresAuth: false },
	},
	{
		path: '/maintenance',
		component: () => import('../components/layouts/LoginLayout.vue'),
		children: [
			{
				path: '',
				name: 'Maintenance',
				component: () => import('../views/MaintenanceView.vue'),
			}
		]
	},
	{
		path: '/unreachable',
		component: () => import('../components/layouts/LoginLayout.vue'),
		children: [
			{
				path: '',
				name: 'Unreachable',
				component: () => import('../views/UnreachableView.vue'),
			}
		]
	},
	{
		path: '/:pathMatch(.*)*',
		component: () => import('../views/NoFoundView.vue'),
	}
]

// Crée l'instance du router
const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach(async (to, from, next) => {
	const authStore = useAuthStore();

	if (to.meta.requiresAuth) {
		await authStore.checkAuth(to.fullPath ?? null);
	}

	next();
});

export default router
