import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import './plugins/apiClient';
import * as Sentry from "@sentry/vue";


const app = createApp(App)

Sentry.init({
	app,
	dsn: "https://fecdceb4fb9b94c7ef5ba73f55db55e5@o4508845534150656.ingest.de.sentry.io/4508846732279888",
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(createPinia())

app.use(router)
app.use(i18n)
app.mount('#app')
