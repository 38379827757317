import type { GlobalThemeOverrides } from 'naive-ui';

const themeOverrides: GlobalThemeOverrides = {
  common: {
    // Couleurs personnalisées pour les états globaux
    primaryColor: '#3f51b5',
    primaryColorHover: '#4d5fc1',
    primaryColorPressed: '#3949a3',
    errorColor: '#dc3545',
    errorColorHover: '#e4606d',
    errorColorPressed: '#c82333',
  },
};

export default themeOverrides;
