import axios from 'axios';

const baseURLV1 = import.meta.env.VITE_API_BASE_URL
console.log('MPEL API: ' + baseURLV1);

const apiClientV1 = axios.create({
	baseURL: baseURLV1,
	timeout: 30000,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer ' + localStorage.getItem('token')
	},
});

const fileDownloadClientV1 = axios.create({
	baseURL: baseURLV1,
	timeout: 60000,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer ' + localStorage.getItem('token')
	},
	responseType: 'blob',
});

export { apiClientV1, fileDownloadClientV1 };
