import { defineStore } from 'pinia';
import { apiClientV1 } from '@/plugins/apiClient';
import { AxiosError } from 'axios';
import router from '@/router';
import type { ApiResponse, Token, Credentials, ClientAuth } from '@/types';
import { createDiscreteApi } from 'naive-ui';
import { jwtDecode } from "jwt-decode";
import { h } from 'vue';
import * as Sentry from '@sentry/vue';

const { notification } = createDiscreteApi(['notification']);

export const useAuthStore = defineStore('auth', {
	state: () => ({
		user: null as ClientAuth | null,
		token: null as string | null,
		lastChangelogSeen: undefined as string | undefined,
	}),
	actions: {
		// Connexion utilisateur
		async login(credentials: Credentials): Promise<void> {
			try {
				const { data: response } = await apiClientV1.post<ApiResponse<Token & ClientAuth>>(
					'/clients/login',
					credentials,
				)

				if (!response.data) {
					throw new AxiosError('An error occured.')
				}

				this.token = response.data.token
				this.user = response.data
				this.lastChangelogSeen = response.data.lastChangelogSeen

				localStorage.setItem('token', response.data.token)
				localStorage.setItem('user', JSON.stringify(response.data))
				localStorage.setItem('lastChangelogSeen', response.data.lastChangelogSeen ?? '')

				if (localStorage.getItem('isExpanded') === null) {
					localStorage.setItem('isExpanded', 'true')
				}

				if (response.data.isFirstLogin) {
					localStorage.setItem('firstLogPass', credentials.password)
					router.push({ name: 'FirstLogin', query: { rl: 'true' } })
					return
				}

				// Check url params
				const params = new URLSearchParams(window.location.search)
				const returnTo = params.get('return')

				if (returnTo) {
					router.push(returnTo + (returnTo.includes('?') ? '&' : '?') + 'rl=true')
				} else {
					router.push({ name: 'Home', query: { rl: 'true' } })
				}
			} catch (error: unknown) {
				Sentry.captureException(error)
				throw error
			}
		},

		// Déconnexion utilisateur
		async logout(returnIfLogout: string | null, manual: boolean): Promise<void> {
			this.token = null
			this.user = null
			localStorage.removeItem('token')
			localStorage.removeItem('user')
			localStorage.removeItem('firstLogPass')
			router.push({ name: 'Login', query: { return: returnIfLogout } })
			if (!manual) return
			notification.success({
				title: 'Déconnexion',
				description: 'Vous avez bien été déconnecté.',
				duration: 5000,
			})
		},

		async verifyAuth(returnIfLogout: string | null, delay: number = 0): Promise<boolean> {
			let isConnected = true
			this.token = localStorage.getItem('token')
			this.user = JSON.parse(localStorage.getItem('user') as string)
			this.lastChangelogSeen = localStorage.getItem('lastChangelogSeen') ?? ''

			if (this.token) {
				const expiration = jwtDecode(this.token as string).exp
				const now = Math.round(new Date().getTime() / 1000)

				if (!expiration || expiration - delay < now) {
					isConnected = false
				}
			} else {
				isConnected = false
			}

			if (!this.user) {
				isConnected = false
			}

			if (!isConnected) {
				notification.error({
					title: 'Déconnexion forcé',
					description: 'Votre session a expiré, veuillez vous reconnecter.',
					duration: 5000,
				})
				this.logout(returnIfLogout, false)
				return false
			}
			return true
		},

		async checkAuth(returnIfLogout: string | null): Promise<boolean> {
			return await this.verifyAuth(returnIfLogout)
		},

		async checkAuthForNextMinutes(
			returnIfLogout: string | null,
			minutes: number,
		): Promise<boolean> {
			return await this.verifyAuth(returnIfLogout, minutes * 60)
		},

		async changePassword(password: string | null, newPassword: string): Promise<void> {
			try {
				if (localStorage.getItem('firstLogPass')) {
					password = localStorage.getItem('firstLogPass') as string
					await this.checkAuth(null)
					this.token = localStorage.getItem('token')
					this.user = JSON.parse(localStorage.getItem('user') as string)
				}

				const { data: response } = await apiClientV1.put<ApiResponse<null>>(
					'/clients/change-password',
					{ email: this.user?.email, password, newPassword },
				)

				if (response.status == 'error') {
					throw new AxiosError('An error occured.')
				}

				localStorage.removeItem('firstLogPass')

				notification.success({
					title: 'Succès',
					description: () =>
						h('div', [
							h('p', 'Votre mot de passe a bien été modifié.'),
							h('p', 'Veuillez vous reconnecter avec votre nouveau mot de passe.'),
						]),
					duration: 5000,
					keepAliveOnHover: true,
				})

				this.logout(null, false)
			} catch (error: unknown) {
				throw error
			}
		},
	},
})
